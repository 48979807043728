@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");

@layer base {
  body {
    @apply font-[Raleway];
  }
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}

.content-div:hover {
  background-image: linear-gradient(
    to right,
    rgba(112, 157, 255, 0.8),
    hsla(242, 74%, 61%, 0.8)
  ) !important;
}

html,
body {
  overflow-x: hidden;
}

.accordion-button {
  padding: 0.4rem 2rem;
}

.accordion-button:not(.collapsed) {
  background-color: rgb(230, 119, 59);
}

.accordion-flush .accordion-item .accordion-button {
  color: white;
}

.accordion-body {
  color: white;
}

.proj1 {
  background-image: linear-gradient(to bottom, rgb(0, 0, 0, 0), rgb(0, 0, 0)),
    url("assets/pop.jpg");
  height: 70vh;

  background-repeat: no-repeat;
}

.proj2 {
  background-image: linear-gradient(to bottom, rgb(0, 0, 0, 0), rgb(0, 0, 0)),
    url("assets/faux-venture.png");
  height: 70vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.proj3 {
  background-image: linear-gradient(to bottom, rgb(0, 0, 0, 0), rgb(0, 0, 0)),
    url("assets/jurrasic-park-wide.jpg");
  height: 70vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.proj4 {
  background-image: linear-gradient(to bottom, rgb(0, 0, 0, 0), rgb(0, 0, 0)),
    url("assets/portfolio.png");
  height: 70vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.proj5 {
  background-image: linear-gradient(to bottom, rgb(0, 0, 0, 0), rgb(0, 0, 0)),
    url("assets/gradetier.png");
  height: 70vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.proj6 {
  background-image: linear-gradient(to bottom, rgb(0, 0, 0, 0), rgb(0, 0, 0)),
    url("assets/roundabout.png");
  height: 70vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.proj7 {
  background-image: linear-gradient(to bottom, rgb(0, 0, 0, 0), rgb(0, 0, 0)),
    url("assets/tweets.png");
  height: 70vh;
  background-size: cover;
  background-repeat: no-repeat;
}
